import { useWordPressContext } from 'contexts/wordpress-provider';

const fullDate = new Date();

export const getCurrentMonth = (): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const { translations } = useWordPressContext();

  const month = months[fullDate.getMonth()];

  return translations[month];
};

export const getCurrentYear = (): number => fullDate.getFullYear();

export const getDateMonth = (date: string): string => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthIdx = new Date(date).getUTCMonth();
  return months[monthIdx];
};

export const getDateDay = (date: string): string => {
  const day = new Date(date).getUTCDate().toString();
  return day;
};

export const getDateYear = (date: string): string => {
  const year = new Date(date).getUTCFullYear().toString();

  return year;
};
